import axios from 'axios'

export default {
	namespaced: true,
	state: {
		requestId: '',
		document: null
	},
	getters: {
		getRequestId: state => state.requestId,
		getDocument: state => state.document,
	},
	mutations: {
		mutationRequest(state, data) {
			state.requestId = data
		},
		mutationDocument(state, data) {
			console.log(data)
			state.document = data.data
		},
	},
	actions: {
		createRequest({ rootState, commit }, query) {
			return new Promise((resolve, reject) => {
				axios.post(`${rootState.api}Standarts`, query)
				.then(resp => {
					commit('mutationRequest', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		createDocument({ rootState }, {id, name}) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Standarts/RequestDocument/${id}`,{
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/Docx'
            }
        })
				.then(resp => {
					const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',` ${name}.Docx`);
          document.body.appendChild(link);
          link.click();
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
	}
};
