import axios from 'axios'

export default {
	namespaced: true,
	state: {
		usersList: [],
		newUserId: '',
		updateUserId: '',
		access:[],
		userFilter: [],
		userAccess: '',
	},
	getters: {
		getUsers: state => state.usersList,
		getAccess: state => state.access
	},
	mutations: {
		allUsersMutation(state, data) {
			state.usersList = data.map(item => {
				item.update = false
        return item
      })
			state.userFilter = data
		},
    startUpdateUser(state, index) {
      state.usersList[index].update = !state.usersList[index].update
    },
		setUpdateUser(state, data) {
			state.updateUserId = data
		},
		setCreatedUserr(state, data) {
			state.newUserId = data
		},
		setAccess(state, data) {
			state.access = data
		},
		setSearch(state, {column, string}) {
			state.usersList = state.userFilter.filter(item => {
				if (column == 'roles') {
					let roles = item[column].map(name => {
						return name.name
					})
					console.log(roles.join())
					if (roles.join(' ').toLowerCase().indexOf(string.toLowerCase()) != -1) {
						return item
					}
				} else if (column == 'roleGroup') {
					if (item[column].name.toLowerCase().indexOf(string.toLowerCase()) != -1) {
						return item
					}
				} else {
					if (item[column].toLowerCase().indexOf(string.toLowerCase()) != -1) {
						return item
					}
				}
			})
		},
	},
	actions: {
		allUsers({ rootState, commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Users/All`)
				.then(resp => {
					commit('allUsersMutation', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		updateUser({ rootState, commit }, data) {
			return new Promise((resolve, reject) => {
				axios.put(`${rootState.api}Users/${data.id}`, data.user)
				.then(resp => {
					commit('setUpdateUser', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		blockedUser({ rootState }, data) {
			return new Promise((resolve, reject) => {
				axios.put(`${rootState.api}Users/BLock/${data.id}?block=${data.block}`)
				.then(resp => {
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		createdUser({ rootState, commit }, data) {
			return new Promise((resolve, reject) => {
				axios.post(`${rootState.api}Users`, data)
				.then(resp => {
					commit('setCreatedUserr', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		access({ rootState, commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Menu`)
				.then(resp => {
					commit('setAccess', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		createAccess({ rootState }, data) {
			return new Promise((resolve, reject) => {
				axios.post(`${rootState.api}Menu`, data)
				.then(resp => {
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
	}
};
