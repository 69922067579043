import axios from 'axios'

export default {
	namespaced: true,
	state: {
		reports: [],
		filterReports: []
	},
	getters: {
		getReports: state => state.filterReports,
	},
	mutations: {
		setReports(state, data) {
			state.reports = data
			state.filterReports = []
			data.map(item => {
				if (typeof item.depNumber != 'number') {
					item.depNumber.map((dep, i) => {
						if (i) {
							state.filterReports.push({
								code: false,
								standartName: false,
								clinrec: false,
								rows: item.department.length,
								depNumber: dep,
								department: item.department[i],
								fullName: item.fullName[i],
								voteStatus: item.voteStatus[i],
								setDate: item.setDate[i]
							})
						} else {
							state.filterReports.push({
								code: item.code,
								standartName: item.standartName,
								clinrec: item.clinrec,
								rows: item.department.length,
								depNumber: dep,
								department: item.department[i],
								fullName: item.fullName[i],
								voteStatus: item.voteStatus[i],
								setDate: item.setDate[i]
							})
						}
					})
				} else {
					state.filterReports = data
				}
			})
		},
		setSearch(state, {column, string}) {
			state.filterCatalog = state.catalog.filter(item => {
				if (String(item[column]).search(String(string).toLowerCase()) != -1) {
					return item
				}
			})
		},
	},
	actions: {
		reports({ rootState, commit }, catName) {
			return new Promise((resolve, reject) => {
				axios.post(`${rootState.api}StandartReports/${catName}`)
				.then(resp => {
					commit('setReports', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
	}
};
