import axios from 'axios'
import _ from 'lodash'

export default {
	namespaced: true,
	state: {
		profiles: [],
		departments: [],
		ageGroups: [],
		directorFullName: '',
		code: [],
		name: [],
		roleGroups: [],
		rolesGroupsName: [],
		rolesName: [],
		curentGroup: [],
		services: [],
		medicines: [],
		notes: [],
		notes2: [],
	},
	getters: {
		getNames: state => state.name,
		getCode: state => state.code,
		getProfiles: state => state.profiles,
		getDepartmentsl: state => state.departments,
		getAgeGroups: state => state.ageGroups,
		getDirectorFullName: state => state.directorFullName,
		getRolesName: state => state.rolesName,
		getRolesGroupName: state => state.rolesGroupsName,
		getCurentGroup: state => state.curentGroup,
		getServices: state => state.services,
		getMedicines: state => state.medicines,
		getNotes: state => state.notes,
		getNotes2: state => state.notes2,
	},
	mutations: {
		mutationLookups(state, look) {
			state.profiles = look.profiles
			state.departments = look.departments
			state.departments.splice(4,2)
			state.ageGroups = look.ageGroups
			state.roleGroups = look.roleGroups
			state.rolesName = _.flattenDeep(look.roleGroups.map(item => {
				return item.roles.map(role => {
					return {text:role.name, value: {name:role.name, id: role.id}}
				})
			}))
			state.rolesGroupsName = look.roleGroups.map(item => {
				return {text:item.name, value: {name:item.name, id: item.id}}
			})
		},
		setRolesCurent(state, id) {
			state.curentGroup = []
			state.roleGroups.map(item => {
				if (item.id == id) {
					item.roles.map(role => {
						state.curentGroup.push({text:role.name, value: {name:role.name, id: role.id}})
					})
				}
			})
		},
		setServices(state, data) {
			state.services = _.uniq(data.map(item => {
				return {
					text: item.name,
					value: item.code
				}
			}))
		},
		setNotes(state, data) {
			state.notes = []
			state.notes2 = []
			_.uniq(data.map(item => {
				if (item.typeCode == 2) {
					state.notes.push(item.name)
				} else {
					state.notes2.push(item.name)
				}
			}))
		},
		setMedicines(state, data) {
			state.medicines = _.uniq(_.pull(data.map(item => {
				if (item.name) {
					return {
						text: item.codeNameUnit,
						value: item.code
					}
				}
			}), undefined))
		},
		mutationDirectorFullName(state, id) {
			state.departments.forEach(department => {
				if (department.id == id) {
					state.directorFullName = department.directorFullName
				}
			});
		}
	},
	actions: {
		lookups({ rootState, commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Lookups`)
				.then(resp => {
					commit('mutationLookups', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		services({ rootState, commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Services`)
				.then(resp => {
					commit('setServices', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		medicines({ rootState, commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Medicines`)
				.then(resp => {
					commit('setMedicines', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		notes({ rootState, commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Lookups/Notes`)
				.then(resp => {
					commit('setNotes', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
	}
};
