<template>
  <div class="home text-center" v-if="!$store.state.login.token">
    <h1>
      Добро пожаловать в <b>Систему разработки и ведения стандартов медицинской помощи.</b>
    </h1>
    <h2>Для доступа к Системе нажмите кнопку "Вход" в <br> верхней правой части страницы.</h2>
  </div>
  <div class="page" v-else>
    <h2 class="main-title">{{title}}</h2>
    <nav class="sub-menu">
      <span
        v-for="link in links"
        :key="link.name"
        class="link"
        @click="filterStatus(link.status)">
        {{link.name}}
      </span>
    </nav>
    <div class="button-box">
      <router-link
        class="add"
        v-if="!($store.state.login.cekkmp || $store.state.login.gbs || $store.state.login.guest)"
        :to="{name: 'create'}">
        <img src="@/assets/icon/pluse.svg" />
        Создать запроc
      </router-link>
      <button
        @click="generateReportsFiles()"
        class="add">
        <img src="@/assets/icon/check.svg" />
        Сформировать отчет
      </button>
    </div>
    <div class="responsive">
      <v-skeleton-loader
        v-if="getSMPLoading"
        class="mx-auto"
        type="table-tbody"
      ></v-skeleton-loader>
      <table class="table table-search table-link" v-else>
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="text-center">
              <span class="table-title" @click="sort(header.value)">{{header.text}} <span v-if="header.value === sortValue.column" :class="['icon', {'ask': sortValue.ask}]">&#8595;</span></span>
              <div class="search">
                <input type="text" v-model="header.input" @input="setSearch({column:header.value, string:header.input})">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(smp, index) in getSMP"
            @click="routLink(smp.id)"
            :key="index"
          >
            <td class="text-left">
              <div class="stage">{{smp.data}}</div>
            </td>
            <td class="text-center">{{smp.name}}</td>
            <td class="text-center">
                <div class="item">{{smp.clinicalRecommendations}}</div>
            </td>
            <td class="text-center"><div class="item">{{smp.mkbCode}}</div></td>
            <td class="text-center"><div class="item">{{smp.mkbName}}</div></td>
            <td class="text-center"><span v-if="smp.ageGroup">{{smp.ageGroup}}</span></td>
            <td class="text-center">
                <div class="item">{{smp.medicalCareTypes}}</div>
            </td>
            <td class="text-center">{{smp.status}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
  name: 'SMP',
  data () {
    return {
      sortValue: {
        column: null,
        ask: false,
      },
      title: 'Стандарты Медицинской Помощи',
      links: [
        {name: 'Все', status: ''},
        {name: 'Запрос', status: '0DD880C6-ACAA-4F97-9AA2-1E0AD441A5C9'},
        {name: 'Проект', status: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'},
        {name: 'Стандарт медицинской помощи', status: '7E04AEB0-48C7-4880-838F-3D6DDD0724F2'}
      ],
      filter: {},
      headers: [
        {
          text: '№ и дата',
          value: 'data',
        },
        { text: 'Наименование проекта', value: 'name', input: '' },
        { text: 'Клиническая рекомендация', value: 'clinicalRecommendations' , input: ''},
        { text: 'МКБ-10', value: 'mkbName' , input: ''},
        { text: 'Заболевание', value: 'mkbCode', input: '' },
        { text: 'Возрастная группа', value: 'ageGroup', input: '' },
        { text: 'Вид и условия оказания медицинской помощи', value: 'medicalCareTypes', input: '' },
        { text: 'Статус', value: 'status', input: '' }
      ],
    }
  },
  computed : {
    ...mapGetters({
        getSMP: 'smp/getSMP',
        getSMPLoading: 'smp/getSMPLoading',
    }),
  },
  methods: {
    ...mapActions({
      smpFilter: 'smp/smpFilter',
      generateReportsFiles: 'smp/generateReportsFiles'
    }),
    ...mapMutations({
      setSearch: 'smp/setSearch',
      sortSmp: 'smp/sortSmp',
    }),
    sort(column) {
      this.sortValue.column = column
      this.sortValue.ask = !this.sortValue.ask
      this.sortSmp(column)
    },
    routLink(id) {
      this.$router.push({ name: 'pasport', params: { id: id } })
    },
    filterStatus(status) {
      if(status) this.filter = {stages: [status]}
      else this.filter = {}
      this.smpFilter(this.filter)
    },
  },
  mounted() {
    if (this.$store.state.login.token) this.smpFilter(this.filter).then(() => this.load = true)
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
