import axios from 'axios'

export default {
	namespaced: true,
	state: {
		catalog: [],
		filterCatalog: []
	},
	getters: {
		getСatalog: state => state.filterCatalog,
	},
	mutations: {
		setСatalog(state, data) {
			state.catalog = data
			state.filterCatalog = data
		},
		setSearch(state, {column, string}) {
			state.filterCatalog = state.catalog.filter(item => {
				if (String(item[column]).search(String(string).toLowerCase()) != -1) {
					return item
				}
			})
		},
	},
	actions: {
		catalog({ rootState, commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Catalog/getcatalog`)
				.then(resp => {
					commit('setСatalog', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
	}
};
