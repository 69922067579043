import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/search',
    name: 'search',
    redirect: {name: 'services'},
    component: () => import('../views/Search.vue'),
    children: [
      {
        path: 'medications',
        name: 'medications',
        component: () => import('../views/Medications/Medications.vue')
      },
      {
        path: 'services',
        name: 'services',
        component: () => import('../views/Services/Services.vue')
      },
    ]
  },
  {
    path: '/adminpanel',
    name: 'adminpanel',
    redirect: {name: 'access'},
    component: () => import('../views/Adminpanel.vue'),
    children: [
      {
        path: 'access',
        name: 'access',
        component: () => import('../views/Access/Access.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/Users/Users.vue')
      },
    ]
  },
  {
    path: '/manual',
    name: 'manual',
    component: () => import('../views/Мanual.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: {name: 'project-approval'},
    component: () => import('../views/Reports.vue'),
    children: [
      {
        path: 'project-approval',
        name: 'project-approval',
        component: () => import('../views/Reports/ProjectApproval.vue')
      },
      {
        path: 'list-pasport',
        name: 'list-pasport',
        component: () => import('../views/Reports/ListPasport.vue')
      },
      {
        path: 'list-diagnosis',
        name: 'list-diagnosis',
        component: () => import('../views/Reports/ListDiagnosis.vue')
      },
      {
        path: 'list-nutrition',
        name: 'list-nutrition',
        component: () => import('../views/Reports/ListNutrition.vue')
      },
      {
        path: 'list-food',
        name: 'list-food',
        component: () => import('../views/Reports/ListFood.vue')
      },
      {
        path: 'list-blood',
        name: 'list-blood',
        component: () => import('../views/Reports/ListBlood.vue')
      },
      {
        path: 'list-medicines',
        name: 'list-medicines',
        component: () => import('../views/Reports/ListMedicines.vue')
      },
      {
        path: 'list-products',
        name: 'list-products',
        component: () => import('../views/Reports/ListProducts.vue')
      },
    ]
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import('../views/Archive/Archive.vue')
  },
  {
    path: '/smp',
    name: 'smp',
    redirect: '/',
    component: () => import('../views/SMP.vue'),
    children: [
      {
        path: 'pasport/:id',
        name: 'pasport',
        component: () => import('../views/Pasport/Pasport.vue')
      },
      {
        path: 'note/:id',
        name: 'note',
        component: () => import('../views/Note/Note.vue')
      },
      {
        path: 'diagnostics/:id',
        name: 'diagnostics',
        component: () => import('../views/Diagnostics/Diagnostics.vue')
      },
      {
        path: 'therapy/:id',
        name: 'therapy',
        component: () => import('../views/Therapy/Therapy.vue')
      },
      {
        path: 'medicines/:id',
        name: 'medicines',
        component: () => import('../views/Medicines/Medicines.vue')
      },
      {
        path: 'blood/:id',
        name: 'blood',
        component: () => import('../views/Blood/Blood.vue')
      },
      {
        path: 'products/:id',
        name: 'products',
        component: () => import('../views/Products/Products.vue')
      },
      {
        path: 'food/:id',
        name: 'food',
        component: () => import('../views/Food/Food.vue')
      },
    ]
  },
  {
    path: '/request',
    component: () => import('../views/Request/Request.vue'),
    children: [
      {
        path: 'create',
        name: 'create',
      },
      {
        path: ':id',
        name: 'request',
      }
    ]
  },
  {
    path: '/smp/documents/:id',
    name: 'documents',
    component: () => import('../views/Documents/Documents.vue')
  },
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
