import axios from 'axios'
import HomeIcon from '@/assets/icon/home.svg'
import SearchIcon from '@/assets/icon/search.svg'
import ListIcon from '@/assets/icon/list.svg'
import InfoIcon from '@/assets/icon/info.svg'
import BoxIcon from '@/assets/icon/box.svg'

export default {
	namespaced: true,
	state: {
		token: localStorage.getItem('token') || '',
		user : {},
		restoreMessage: null,
		passwordMessage: null,
		status: null,
    employee: false,
    administrator: false,
    routs: [
      {name: 'Стандарты', accessName: 'Стандарты медицинской помощи', to: {name: 'smp'}, icon: HomeIcon, access: false},
      {name: 'Поиск по услуге/ЛС', accessName: 'Поиск по услуге/ЛС', to: {name: 'search'}, icon: SearchIcon, access: false},
      {name: 'Согласование', accessName: 'Согласование', to: {name: 'reports'}, icon: ListIcon, access: false},
      {name: 'Справочники', accessName: 'Справочники', to: {name: 'manual'}, icon: InfoIcon, access: false},
      {name: 'Архив', accessName: 'Архив', to: {name: 'archive'}, icon: BoxIcon, access: false},
      {name: 'Администрирование', accessName: 'Администрирование', to: {name: 'adminpanel'}, icon: BoxIcon, access: false },
    ],
		subMenu: [
			{name: 'Доступ к меню', accessName: 'Доступ к меню', link: {name: 'access'}, access: false, page: 'adminpanel'},
			{name: 'Список пользователей', accessName: 'Пользователи', link: {name: 'users'}, access: false, page: 'adminpanel'},
			{name: 'Услуги', accessName: 'Услуги', link: {name: 'services'}, access: true, page: 'search'},
			{name: 'Лекарственные препараты', accessName: 'Лекарственные препараты', link: {name: 'medications'}, access: true, page: 'search'},
			{name: 'Запрос', accessName: 'Запрос', link: {name: 'request'}, access: true, page: 'smp'},
			{name: 'Стандарт', accessName: 'Стандарт', link: {name: 'pasport'}, access: true, page: 'smp'},
			{name: 'Документы', accessName: 'Документы', link: {name: 'documents'}, access: true, page: 'smp'}
		],
		sideBar: [
			{to: 'project-approval', accessName: 'Отчет по результатам согласования проектов', name: 'Отчет по результатам согласования проектов', access: false, page: 'reports'},
			{to: 'list-pasport', accessName: 'Список замечаний (Общее. Паспортная часть)', name: 'Список замечаний (Паспорт)', access: false, page: 'reports'},
			{to: 'list-diagnosis', accessName: 'ОСписок замечаний (Диагностика. Лечение)', name: 'Список замечаний (Диагностика /Лечение)', access: false, page: 'reports'},
			{to: 'list-food', accessName: 'Список замечаний (Питание)', name: 'Список замечаний (Питание)', access: false, page: 'reports'},
			{to: 'list-blood', accessName: 'Список замечаний (Кровь)', name: 'Список замечаний  (Кровь)', access: false, page: 'reports'},
			{to: 'list-medicines', accessName: 'Список замечаний (Лекарства)', name: 'Список замечаний (Лекарства)', access: false, page: 'reports'},
			{to: 'list-products', accessName: 'Список замечаний (Изделия)', name: 'Список замечаний (Изделия)', access: false, page: 'reports'},
			{to: 'pasport', accessName: 'Паспорт', name: 'Паспорт', access: true, page: 'smp'},
			{to: 'diagnostics', accessName: 'Диагностика', name: 'Диагностика', access: true, page: 'smp'},
			{to: 'therapy', accessName: 'Лечение', name: 'Лечение', access: true, page: 'smp'},
			{to: 'medicines', accessName: 'Лекарства', name: 'Лекарства', access: true, page: 'smp'},
			{to: 'blood', accessName: 'Кровь', name: 'Кровь', access: true, page: 'smp'},
			{to: 'products', accessName: 'Изделия', name: 'Изделия', access: true, page: 'smp'},
			{to: 'food', accessName: 'Питание', name: 'Питание', access: true, page: 'smp'},
			{to: 'note', accessName: 'Примечания', name: 'Примечания', access: true, page: 'smp'},
		],
		curentSideBar: [],
		curentSubMenu: [],
    gbs: false,
    cekkmp: false,
    guest: false,
		role: '',
		blocked: false,
		mainDepartment: null,
	},
	getters: {
		getRestoreMessage: state => state.restoreMessage,
		getPasswordMessage: state => state.passwordMessage,
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
		getUser: state => state.user,
		isEmployee: state => state.employee,
		isAdmin: state => state.administrator,
		getRouts: state => state.routs,
		isBlocked: state => state.blocked,
		isGbs: state => state.gbs,
		getSubMenu: state => state.curentSubMenu,
		getSideBar: state => state.curentSideBar,
		isCekkmp: state => state.cekkmp,
		isGuest: state => state.guest,
		isMinzdrav: state => state.minzdrav
	},
	mutations: {
		auth_request(state){
			state.status = 'loading'
		},
		auth_success(state, token){
			state.status = 'success'
			state.token = `Bearer ${token}`
		},
		menuMutations(state, page){
			state.curentSideBar = state.sideBar.filter(item => item.page === page && item.access === true)
			state.curentSubMenu = state.subMenu.filter(item => item.page === page && item.access === true)
		},
		userData(state, data) {
			state.user = data
			state.mainDepartment = data.mainDepartment
			state.employee = false
			state.administrator = false
			state.gbs = false
			state.cekkmp = false
			state.guest = false
			state.role = data.roleGroup.name
			data.roles.map(item => {
				switch (item.id.toLowerCase()) {
					case 'b78e904c-345f-40f0-8948-dcb479e579ab'.toLowerCase():
						state.employee = true
						break;
					case '178f41fe-59d3-4d58-9031-71ccc07f64ca'.toLowerCase():
						state.administrator = true
						break;
					case '90262588-05ee-4d3d-bc16-1e171be0bd7f'.toLowerCase():
						state.gbs = true
						break;
					case 'ea6fc0ee-564d-416e-84f8-c9349f44ad3d'.toLowerCase():
						state.cekkmp = true
						break;
					case '45ae27e5-19d3-4af8-8a75-e82eaad0b224'.toLowerCase():
						state.guest = true
						break;
					default:
				}
			})
			state.routs.map(link => {
				link.access = false
			})
			state.subMenu.map(link => {
				if (link.page === 'adminpanel') {
					link.access = false
				}
			})
			state.user.roleAccesMenuInfo.map(item => {
				state.routs.map(link => {
					if (link.accessName === item.name) {
						link.access = true
					}
				})
				state.subMenu.map(link => {
					if (link.accessName === item.name && link.page === 'adminpanel') {
						link.access = true
					}
				})
				state.sideBar.map(link => {
					if (link.accessName === item.name && link.page === 'reports') {
						link.access = true
					}
				})
			})
		},
		restorMutation(state, message) {
			state.restoreMessage = message
		},
		passwordMutation(state, message) {
			state.passwordMessage = message
		},
		auth_error(state){
			state.status = 'error'
		},
		auth_blocked(state){
			state.status = 'blocked'
			state.blocked = true
		},
		logout(state){
			state.status = ''
			state.token = ''
		},
	},
	actions: {
		login({ rootState, commit }, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios.post(`${rootState.api}Users/Login`, user)
				.then(resp => {
						const token = resp.data
						localStorage.setItem('token', `Bearer ${token}`)
						axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
						commit('auth_success', token)
						resolve(resp)
				})
				.catch(err => {
					commit('auth_error')
					localStorage.removeItem('token')
					reject(err)
				})
			})
		},
		logout({commit}) {
			return new Promise((resolve, reject) => { // eslint-disable-line
				commit('logout')
				localStorage.removeItem('token')
				localStorage.removeItem('roles')
				localStorage.removeItem('name')
				delete axios.defaults.headers.common['Authorization']
				resolve()
			})
		},
		restoreToken({ rootState, commit }, email) {
			return new Promise((resolve, reject) => {
				axios.post(`${rootState.api}Users/Token/${email}`)
				.then(resp => {
					resolve(resp)
					commit('restorMutation', 'Код отправлен вам на E-mail')
				})
				.catch(err => {
					commit('restorMutation', 'Неправильный E-mail')
					reject(err)
				})
			})
		},
		restorePasword({ rootState, commit }, data) {
			return new Promise((resolve, reject) => {
				axios.put(`${rootState.api}Users/Password`, data)
				.then(resp => {
					commit('passwordMutation', 'Пароль успешно изменен')
					resolve(resp)
				})
				.catch(err => {
					if (err.response.status == 400) {
						commit('passwordMutation', 'Пароль не может содержать меньше 6 символов')
					} else {
						commit('passwordMutation', 'Неправильный код')
					}
					reject(err)
				})
			})
		},
		user({rootState, commit}) {
			return new Promise((resolve, reject) => { // eslint-disable-line
				axios.get(`${rootState.api}Users`).then(resp => {
					if (!resp.data.isBlocked) {
						commit('userData', resp.data)
						resolve(resp)
					} else {
						commit('userData', resp.data)
						commit('auth_blocked')
						localStorage.removeItem('token')
					}
				})
				.catch(err => {
					reject(err)
				})
			})
		},
	}
};
