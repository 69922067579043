<template>
  <v-app>
    <Header/>
    <v-main v-bind:class="{logined: $store.state.login.token}">
      <v-container>
        <Menu v-if="$store.state.login.token"/>
        <router-view/>
      </v-container>
    </v-main>
    <Footer v-if="$store.state.login.token"/>
  </v-app>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Menu from '@/components/Menu/Menu.vue'
import Footer from '@/components/Footer/Footer.vue'
import {mapActions, mapMutations} from 'vuex'

export default {
  components: {
    Header,
    Menu,
    Footer
  },
  methods: {
    ...mapActions({
      logout: 'login/logout',
      user: 'login/user',
    }),
    ...mapMutations({
      menuMutations: 'login/menuMutations'
    }),
  },
  created() {
    this.$http.interceptors.response.use(undefined,(err) => {
      return new Promise((resolve, reject) => { // eslint-disable-line
        if (err.response.status === 401) {
          this.logout()
          this.$router.push('/')
        }
        throw err;
      })
    })
    this.user().then(() => {
      if (this.$route.matched[0]) {
        this.menuMutations(this.$route.matched[0].name)
      }
    })
  }
}
</script>

<style lang="scss">
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "~@/scss/global.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>
