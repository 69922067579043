import axios from 'axios'
import _ from 'lodash'

export default {
	namespaced: true,
	state: {
		archive: [],
		pageArchive: [],
		pages: null,
		filterId: [],
		itemPerPage: 50,
		sortColumn: '',
	},
	getters: {
		getArchive: state => state.pageArchive,
		getPages: state => state.pages,
		getFilterId: state => state.filterId
	},
	mutations: {
		setArchive(state, data) {
			state.archive = _.sortBy(data, [function(o) { return o.chainId }])
			state.filterId = state.archive.map(item => {
				return item.id
			})
			state.pages = Math.round(state.archive.length / state.itemPerPage) - 1
			state.pageArchive = state.archive.slice(0, state.itemPerPage)
		},
		setSort(state, column) {
			if (state.sortColumn === column) {
				state.archive = state.archive.reverse()
			} else {
				state.sortColumn = column
				state.archive = _.sortBy(state.archive, [function(o) { return o[column] }])
			}

			state.pageArchive = state.archive.slice(0, state.itemPerPage)
		},
		setPageArchive(state, page) {
			let items = page * state.itemPerPage + state.itemPerPage
			state.pageArchive = state.archive.slice(page * state.itemPerPage, items)
		},
		setSearch(state, {column, string}) {
			let newString = String(string)
			state.filterId = []
			state.pageArchive = state.archive.filter(item => {
				if (String(item[column]).toLowerCase().search(newString.toLowerCase()) != -1) {
					state.filterId.push(item.id)
					return item
				}
			})
			state.pages = Math.round(state.pageArchive.length / state.itemPerPage) - 1
		},
	},
	actions: {
		actionArchive({ rootState, commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Standarts/getarchives`)
				.then(resp => {
					commit('setArchive', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		actionSaveArchive({ rootState }, {id, name}) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Standarts/downloadarchivefilebyid?id=${id}`,{
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/Docx'
            }
        })
				.then(resp => {
					const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}.Docx`); //or any other extension
          document.body.appendChild(link);
          link.click();
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		actionSaveArchiveById({ rootState }, id) {
			return new Promise((resolve, reject) => {
				axios.post(`${rootState.api}Standarts/selectedarchivefiles`, id, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/Docx'
            }
        })
				.then(resp => {
					const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `allArchive.zip`); //or any other extension
          document.body.appendChild(link);
          link.click();
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		actionSaveAllArchive({ rootState }) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Standarts/downloadarchivefiles`,{
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/Docx'
            }
        })
				.then(resp => {
					const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Archive.zip'); //or any other extension
          document.body.appendChild(link);
          link.click();
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
	}
};
