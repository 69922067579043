<template>
  <header v-bind:class="{ login: $store.state.login.token, 'header': true }">
    <v-container fluid v-if="!$store.state.login.token">
      <v-row align="center">
        <v-col cols="2">
          <router-link to="/"><img
            :src="require('../../assets/logo.svg')"
            height="77"
          /></router-link>
        </v-col>
        <v-col cols="8" align="center">
          <h2>Система разработки и ведения стандартов медицинской помощи</h2>
        </v-col>
        <v-col cols="2">
          <Auth/>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="6" class="flex-start">
          <router-link to="/">
          <img
            :src="require('../../assets/logo.svg')"
            height="55"
            class="logo"
          />
          </router-link>
          <p class="header-title">Система разработки и ведения<br> стандартов медицинской помощи</p>
        </v-col>
        <v-col cols="6" class="flex-end">
          <p class="name">Добро пожаловать, <span>{{getUser.fullName || getUser.login}}</span></p>
          <button class="btn btn-help" @click="openDialog()">Помощь</button>
          <button
            @click="logout().then(res => {
              if ($route.name != 'home') $router.push({name: 'home'})
            })"
            class="btn btn-enter"
          >
            Выход
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialogHelp"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Помощь
        </v-card-title>

        <v-card-text>
          <a :href="link" target="_blank">Руководство пользователя</a><br>
          Служба технической поддержки: <br>
          <a href="mailto:support@nitrosbase.com">support@nitrosbase.com</a><br>
          Часы работы: 09:00-18:00 (МСК)
        </v-card-text>
      </v-card>
    </v-dialog>
  </header>
</template>

<script>
import Auth from '@/components/Auth/Auth.vue'
import {mapGetters, mapActions} from 'vuex'

export default {
  components: {
    Auth
  },
  data: () => ({
    dialogHelp: false,
    link: 'https://standart.minzdrav.gov.ru/static/gvs.pdf',
  }),
  computed : {
    ...mapGetters({
        getUser: 'login/getUser'
    }),
  },
  methods: {
    ...mapActions({
      logout: 'login/logout',
    }),
    openDialog() {
      this.dialogHelp = true
      const role = this.getUser.roles[0].name
      this.dialogHelp = true
      if (role === 'ЦЭККМП') {
        this.link = 'https://standart.minzdrav.gov.ru/static/cekkmp.pdf'
      } else if (role === 'Сотрудник Минздрава') {
        this.link = 'https://standart.minzdrav.gov.ru/static/minzdrav.pdf'
      } else {
        this.link = 'https://standart.minzdrav.gov.ru/static/gvs.pdf'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
