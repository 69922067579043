import axios from 'axios'

export default {
	namespaced: true,
	state: {
		documents: [],
		document: [],
	},
	getters: {
		getDocuments: state => state.documents
	},
	mutations: {
		documentsMutation(state, data) {
			state.documents = data
		},
		getDocumentMutation(state, data) {
			state.document = data
		}
	},
	actions: {
		getDocument({ rootState, commit }, id) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Standarts/Files/${id}`, { responseType: 'arraybuffer' })
				.then(resp => {
					commit('getDocumentMutation', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		createDocument({ rootState  }, data ) {
			return new Promise((resolve, reject) => {
				const formData = new FormData()
				formData.append('File', data.File)
				formData.append('StandartId', data.StandartId)
				axios.post(`${rootState.api}Standarts/Files`, formData, { 'Content-Type': 'multipart/form-data' })
				.then(resp => {
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		documents({ rootState, commit }, id) {
			return new Promise((resolve, reject) => {
				axios.get(`${rootState.api}Standarts/Files/All/${id}`)
				.then(resp => {
					commit('documentsMutation', resp.data)
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
		removeDocuments({ rootState }, id) {
			return new Promise((resolve, reject) => {
				axios.delete(`${rootState.api}Standarts/Files?id=${id}`)
				.then(resp => {
					resolve(resp)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
	}
};
