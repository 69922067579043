import axios from 'axios'
import moment from 'moment'
import convert from '@/components/standart.js'

export default {
    namespaced: true,
    state: {
        filterSMP: [],
        smpState: [],
        smploading: true,
        id: '',
        loading: false,
        smpFilter: [],
        title: '',
        pasport: {},
        medicine: [],
        blood: {},
        products: [],
        medications: [],
        stageName: '',
        therapy: [
          {
                title: 'Прием (осмотр, консультация) и наблюдение врача-специалиста',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Наблюдение и уход за пациентом средним и младшим медицинским работником',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Лабораторные методы исследования',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Инструментальные методы исследования',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Иные методы исследования',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Хирургические, эндоскопические, эндоваскулярные и другие методы лечения, требующие анестезиологического и/или реаниматологического сопровождения ',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Немедикаментозные методы профилактики, лечения и медицинской реабилитации',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
        ],
        diet: [
          {
                title: 'Лечебное питание',
                header: [
                    'Наименование вида лечебного питания',
                    'Усредненный показатель частоты предоставления',
                    'Количество',
                ],
                food: []
            },
            {
                title: 'Специализированное питание',
                header: [
                    'Наименование вида питания',
                    'Усредненный показатель частоты предоставления',
                    'ССД',
                    'СКД',
                    'Единица измерения',
                ],
                food: []
            },
        ],
        diagnostics: [
          {
                title: 'Прием (осмотр, консультация) и наблюдение врача-специалиста',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Лабораторные методы исследования',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Инструментальные методы исследования',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            },
            {
                title: 'Иные методы исследования',
                header: [
                    'Код медицинской услуги',
                    'Наименование медицинской услуги',
                    'Усредненный показатель частоты предоставления',
                    'Усредненный показатель кратности применения'
                ],
                service: []
            }
        ],
        stageOne: false,
        stageTwo: false,
        statusOne: false,
        statusTwo: false,
        statusTree: false,
        statusFour: false,
        statusFive: false,
        statusSix: false,
        statusSeven: false,
        stageEight: false,
        statusNine: false,
        statusTen: false,
        statusEleven: false,
        statusTwelve: false,
        statusThirteen: false,
        statusFourteen: false,
        statusFifteen: false,
        statusSixteen: false,
        statusCanClone: true,
        edit: false,
        wordName: '',
        user: '',
        notesTypeTwo: [],
        reverse: false,
        sortSmp: null,
    },
    getters: {
        getWordName: state => state.wordName,
        getSMP: state => state.smpState,
        getSMPLoading: state => state.smploading,
        searchLoading: state => state.loading,
        getPasport: state => state.pasport,
        getTitle: state => state.title,
        getMedicine: state => state.medicine,
        getBlood: state => state.blood,
        getProducts: state => state.products,
        getMedications: state => state.medications,
        getDiet1: state => state.diet[0],
        getDiet2: state => state.diet[1],
        getTherapy: state => state.therapy,
        getDiagnostics: state => state.diagnostics,
        getStageName: state => state.stageName,
        isEdit: state => state.edit,
        getNotesTypeTwo: state => state.notesTypeTwo,
        getId: state => state.id
    },
    mutations: {
      smploadingMutation(state, data){
        state.smploading = data;
      },
      filterMutation(state, data) {
          state.filterSMP = data
          state.smpState = data.sort((a,b) => {
            return b.number - a.number
          }).map(item => {
            return {
              data: `${item.stage.name} №${item.number} ${moment(item.createdOn).format("DD.MM.YY") }`,
              name: item.name,
              clinicalRecommendations: item.clinicalRecommendations.map(cr => {
                return cr.name
              }).join(" "),
              mkbName: item.mkBs.map(mkb => {
                return mkb.name
              }).join(" "),
              mkbCode: item.mkBs.map(mkb => {
                return mkb.code
              }).join(" "),
              id: item.id,
              ageGroup: item.ageGroup ? item.ageGroup.name : '',
              medicalCareTypes: item.medicalCareTypes.map(med => {
                return med.name
              }).join(" "),
              status: item.status.name
            }
          })
          state.smpFilter = state.smpState
          state.smploading = false;
      },
      smpSearch(state, bool) {
          state.loading = bool
      },
      setStandart(state, data) {
          const newData = convert(data)
          state.wordName = newData.passportData.stdId
          state.pasport = newData.passportData ? [
              {
                  label: 'Стадия стандарта',
                  value: newData.passportData.stageName,
                  key: 'stageName'
              }, {
                  label: 'ID стандарта',
                  value: newData.passportData.stdId,
                  key: 'stdId'
              }, {
                  label: 'Наименование стандарта медицинской помощи',
                  value: newData.passportData.name,
                  key: 'name'
              }, {
                  label: 'Возрастная категория пациента',
                  value: newData.passportData.ageGroup,
                  key: 'ageGroup'
              }, {
                  label: 'Пол пациента',
                  value: newData.passportData.gender,
                  key: 'gender'
              }, {
                  label: 'Вид медицинской помощи',
                  value: newData.passportData.medicalCareTypeName,
                  key: 'medicalCareTypeName'
              }, {
                  label: 'Условия оказания медицинской помощи',
                  value: newData.passportData.medicalCareConditionName,
                  key: 'medicalCareConditionName'
              }, {
                  label: 'Форма оказания медицинской помощи',
                  value: newData.passportData.madicalCareFormName,
                  key: 'madicalCareFormName'
              }, {
                  label: 'Фаза течения заболевания (состояния)',
                  value: newData.passportData.diseasePhase,
                  key: 'diseasePhase'
              }, {
                  label: 'Стадия и (или) степень тяжести заболевания (состояния)',
                  value: newData.passportData.disease,
                  key: 'disease'
              }, {
                  label: 'Осложнения',
                  value: newData.passportData.complication,
                  key: 'complication'
              }, {
                  label: 'Средняя продолжительность лечения законченного случая (количество дней)',
                  value: newData.passportData.midTreatmentDuratation,
                  key: 'midTreatmentDuratation'
              }, {
                  label: 'Нозологические единицы (код по МКБ X)',
                  value: newData.passportData.diagCode,
                  key: 'diagCode'
              },
              {
                  label: 'Статус стандарта',
                  value: newData.passportData.statusName,
                  key: 'statusName'
              }, {
                  label: 'Номер приказа Минздрава России',
                  value: newData.passportData.orderNumber,
                  key: 'orderNumber'
              }, {
                  label: 'Дата приказа Минздрава России',
                  value: newData.passportData.orderDate,
                  key: 'orderDate'
              }, {
                  label: 'Наименование клинической рекомендации',
                  value: newData.passportData.clinicalRecommendationName,
                  key: 'clinicalRecommendationName'
              }, {
                  label: 'ID клинической рекомендации',
                  value: newData.passportData.clinId,
                  key: 'clinId'
              }, {
                  label: 'Год утверждения клинической рекомендации',
                  value: newData.passportData.clinRecApprovementYear,
                  key: 'clinrecApprovementYear'
              },
          ] : []
          state.notesTypeTwo = []
          if (newData.notes) {
            newData.notes.map(item => {
              if (item.typeCode == 2) {
                state.notesTypeTwo.push([item.num, item.name])
              }
            })
          }

          state.user = newData.passportData.createdById.value
          state.title = newData.passportData.number.value
          state.id = newData.passportData.id.value
          state.medicine = newData.medicine.data.map(item => {
            return [item[2], item[3], item[4], item[7], item[5], item[6]]
          })
          state.blood = newData.bloodComponent.data
          state.medications = newData.service
          state.products = newData.products.data
          state.therapy[0].service = newData.service.data[5]
          state.therapy[1].service = newData.service.data[6]
          state.therapy[2].service = newData.service.data[7]
          state.therapy[3].service = newData.service.data[8]
          state.therapy[4].service = newData.service.data[9]
          state.therapy[5].service = newData.service.data[10]
          state.therapy[6].service = newData.service.data[11]
          state.diagnostics[0].service = newData.service.data[1]
          state.diagnostics[1].service = newData.service.data[2]
          state.diagnostics[2].service = newData.service.data[3]
          state.diagnostics[3].service = newData.service.data[4]
          state.diet[0].food = newData.diet1.data
          state.diet[1].food = newData.diet2.data
          state.stageOne = false
          state.stageTwo = false
          switch (newData.passportData.stageId) {
            case `0DD880C6-ACAA-4F97-9AA2-1E0AD441A5C9`.toLowerCase():
              state.stageOne = true
              break;
            case `783A93EA-AFF3-4D8F-91AC-5A690D4F4961`.toLowerCase():
              state.stageTwo = true
              break;
            default:
          }
          state.statusOne = false
          state.statusTwo = false
          state.statusTree = false
          state.statusFour = false
          state.statusFive = false
          state.statusSix = false
          state.statusSeven = false
          state.statusEight = false
          state.statusNine = false
          state.statusTen = false
          state.statusEleven = false
          state.statusTwelve = false
          state.statusThirteen = false
          state.statusFourteen = false
          state.statusFifteen = false
          state.statusSixteen = false
          state.statusCanClone = true
          switch (newData.passportData.statusId.value.toLowerCase()) {
            case "50C53811-33AF-4894-A0ED-EE412BE9E166".toLowerCase():
              state.statusOne = true // Запрос на разработку от Минздрава отправлен ГВС
              state.statusCanClone = false
              break;
            case "CB2D66F2-8F38-4047-ADA0-9F8CDE03391E".toLowerCase():
              state.statusTwo = true // Запрос принят ГВС, процесс формирования проекта начат
              break;
            case "FB6DE7D6-A278-4D69-91DD-102899B25AC2".toLowerCase():
              state.statusTree = true // Проект сформирован
              break;
            case "1D403C69-4C12-4AD9-BAAA-3EA61F2EA684".toLowerCase():
              state.statusFour = true // На согласовании
              break;
            case "A41BA4D1-7673-45D9-8BE7-45F5FBC53252".toLowerCase():
              state.statusFive = true // Проект доработан
              break;
            case "DD2F4743-310B-460A-89B9-298504425567".toLowerCase():
              state.statusSix = true // Подготовка Акта
              break;
            case "7EF17CC8-295F-45A0-9BA9-03D9A6DCC331".toLowerCase():
              state.statusSeven = true // Утвержден
              state.statusCanClone = false
              break;
            case "E75768B0-D4F7-44D8-8714-CE30E32AB36E".toLowerCase():
              state.statusEight = true // Рассмотрение предложений
              break;
            case "6EB8EC1D-3F7A-4EE4-8747-51A0FC3C54CA".toLowerCase():
              state.statusNine = true // Рассмотрение заключения
              break;
            case "7928F146-8B99-4396-A503-01A50027E8AF".toLowerCase():
              state.statusTen = true // Доработка не требуется, регистрация Акта
              break;
            case "2C970AF2-74D6-44CF-A546-72AFB38C37A2".toLowerCase():
              state.statusEleven = true // Отправлен на доработку
              break;
            case "2D63E640-45D9-494B-A0E3-3A244B5FA779".toLowerCase():
              state.statusTwelve = true // Проект согласован, отправлен на МЭО
              break;
            case "9333206B-70FB-46FF-9D9F-E9A8DC97698E".toLowerCase():
              state.statusThirteen = true // Минздрав оповещен, продолжается разработка проекта
              break;
            case "92B0ED1A-FBD2-4FAD-B542-09BBA8D8F976".toLowerCase():
              state.statusFourteen = true // Отправлено уведомление Минздраву
              break;
            case "DF742E24-CF39-4A4B-8370-13B1C2C622A5".toLowerCase():
              state.statusFifteen = true // ???
              break;
            case "50DA9807-A277-49FF-94F3-4FF88DFE959E".toLowerCase():
              state.statusSixteen = true // Предложения по доработке рассмотрены
              break;
            default:
              console.log('Нет такого статуса')
          }

      },
      setSearch(state, {column, string}) {
        state.smpState = state.smpFilter.filter(item => {
          if (item[column].indexOf(string.toLowerCase()) != -1) {
            return item
          }
        })
      },
      setEditor(state) {
          state.edit = !state.edit
        },
      sortSmp(state, column) {
        state.sortSmp = column
        state.smpState.sort((a, b) => {
          if ( a[column] < b[column] ){
            return state.reverse ? -1 : 1;
          }
          if ( a[column] > b[column] ){
            return state.reverse ? 1 : -1;
          }
          return 0;
        })
        state.reverse = !state.reverse
      }
    },
    actions: {
        smpFilter({ rootState, commit }, filter) {
            commit('smploadingMutation', true);
            return new Promise((resolve, reject) => {
                axios.post(`${rootState.api}Standarts/Filtering`, filter)
                    .then(resp => {
                        commit('filterMutation', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        smpDate({ rootState }, data) {
            return new Promise((resolve, reject) => {
                axios.post(`${rootState.api}Standarts/sendtoredev?endDate=${data.date}&code=secretcodeDB244144-DB6D-4A54-B9EC-F1272E40BF00&standartId=${data.id}`)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        generateFile({ rootState }, {id, file, sufix, code}) {
          return new Promise((resolve, reject) => {
            axios.get(`${rootState.api}${file}?id=${id}`,{
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/Docx'
              }
            })
            .then(resp => {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${code}.${sufix}`); //or any other extension
              document.body.appendChild(link);
              link.click();
              resolve(resp)
            })
            .catch(err => {
              reject(err)
            })
          })
        },
        generateReportsFiles({ rootState }) {
          return new Promise((resolve, reject) => {
            axios.get(`${rootState.api}Standarts/report`, {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/Docx'
              }
            })
            .then(resp => {
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `reports.xls`);
              document.body.appendChild(link);
              link.click();
              resolve(resp)
            })
            .catch(err => {
              reject(err)
            })
          })
        },
        standart({ rootState, commit }, id) {
            return new Promise((resolve, reject) => {
                axios.get(`${rootState.api}Standarts/standart?id=${id}`)
                    .then(resp => {
                        commit('setStandart', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        upLoadJson({ rootState }, data) {
            return new Promise((resolve, reject) => {
                const formData = new FormData()
                formData.append('File', data.File)
                formData.append('StandartId', data.StandartId)
                formData.append('MakeCopy', !!data.MakeCopy)
                axios.post(`${rootState.api}Standarts/Json`, formData, { 'Content-Type': 'multipart/form-data' })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        standartUpdate({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios.post(`${rootState.api}Standarts/updatestatus?standartId=${data.standartId}&statusId=${data.statusId}&stageId=${data.stageId}`)// eslint-disable-line
            .then(resp => {
              resolve(resp)
            })
            .catch(err => {
              reject(err)
            })
          })
        },
        addNote({ rootState, getters }, data) {
          const query = {
            standartId: getters.getId,
            name: data.name || '',
            typeCode: data.typeCode || 0,
            section:  data.section || '',
            linkName: data.linkName || '',
            columnName: data.columnName || '',
            filterName: data.filterName || '',
            filterPage: data.filterPage || '',
            filterFreq: data.filterFreq || '',
            filterCountSKD: data.filterCountSKD || '',
            filterUnit: data.filterUnit || '',
            filterSDD: data.filterSDD || '',
            filterATCCode: data.filterATCCode || ''
          }
          return new Promise((resolve, reject) => {
            axios.post(`${rootState.api}Standarts/addnote`, query)
            .then(resp => {
              resolve(resp)
            })
            .catch(err => {
              reject(err)
            })
          })
        },
        deleteStandart({ rootState }, id) {
          return new Promise((resolve, reject) => {
            axios.delete(`${rootState.api}Standarts/DeleteStandart?standartId=${id}`)
            .then(resp => {
              resolve(resp)
            })
            .catch(err => {
              reject(err)
            })
          })
        },
    }
};
